<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <!--
    <template #button-content>
      <feather-icon
        :badge="notifications.unread.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary ml-1"
        >
          {{ notifications.unread.length }} New
        </b-badge>
      </div>
    </li>

    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >

      <b-link
        v-for="unread in notifications.unread"
        :key="unread.id"
      >
        <b-media @click="setNotificationsAsRead(unread)">
          <template #aside>
            <b-avatar
              size="32"
              :src="unread.avatar"
              :text="unread.avatar"
              :variant="unread.type"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ unread.title }}
            </span>
          </p>
          <small class="notification-text">
            {{ unread.subtitle }}
          </small>
        </b-media>
      </b-link>

    </vue-perfect-scrollbar>

    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="setNotificationsAsRead('all')"
      >
        Read all notifications
      </b-button>
    </li>
    -->
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {

  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },

  directives: {
    Ripple,
  },
  props: {
  },
  /*
  data() {
    return {
      notifications: {
        read: [],
        unread: [],
      },

      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },

  computed: {},

  watch: {},

  created() {
    this.getData()
  },

  mounted() {},

  methods: {
    init() {
    },

    getData() {
      this.getUserNotifications()
    },

    manageLoading(flag = true, seconds = 0) {
      seconds *= 1000
      setTimeout(() => { this.loading = flag }, seconds)
    },

    getUserNotifications() {
      this.$store.dispatch('appNotifications/getNotifications')
        .then(response => {
          this.notifications = response.data.data
        })
        .catch(error => {
          console.log('NotificationDropdown', error)
          this.$handleErrorResponse(error)
        })
    },

    setNotificationsAsRead(notificationData = null) {
      if (notificationData == 'all') { notificationData = this.notifications.unread }

      this.$store.dispatch('appNotifications/readNotifications', notificationData)
        .then(response => {
          this.notifications = response.data.data
        })
        .catch(error => {
          console.log('NotificationDropdown', error)
          this.$handleErrorResponse(error)
        })
    },
  },
  */

}
</script>

<style>

</style>
